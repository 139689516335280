import { useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useStore } from "../pages/_app";

const variants = {
  hidden: { opacity: 0, y: 50 },
  enter: {
    opacity: 1,
    y: 0,
    transition: { ease: "easeOut", duration: 0.5, delay: 0 },
  },
  exit: {
    opacity: 0,
    y: -50,
    transition: { ease: "easeIn", duration: 0.5, delay: 0 },
  },
};

export default function Layout(props) {
  const setDarkUIScheme = useStore((state) => state.setDarkUIScheme);
  const setLightUIScheme = useStore((state) => state.setLightUIScheme);

  useEffect(() => {
    props.uiScheme === "light" ? setLightUIScheme() : setDarkUIScheme();
  }, [props.uiScheme]);

  return (
    <LayoutWrapper
      variants={variants}
      initial="hidden" // Set the initial state to variants.hidden
      animate="enter" // Animated state to variants.enter
      exit="exit" // Exit state (used later) to variants.exit
    >
      {props.children}
    </LayoutWrapper>
  );
}

const LayoutWrapper = styled(motion.div)`
  min-height: 100%;
  overflow: auto;
`;
