import { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import Layout from "./Layout";
import { getAllProducts } from "../lib/shopify";
import Image from "next/image";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import NoScrollLink from "./NoScrollLink";
import { priceDecimal, priceRound } from "../lib/formatPrice";

export default function WorkIndex(props) {
  const [activeCollection, setActiveCollection] = useState("all");

  function getCollectionItems() {
    let filteredProducts = props.products;

    if (activeCollection !== "all") {
      filteredProducts = props.products.filter((product) => {
        return (
          product.node.collections.edges[0] &&
          product.node.collections.edges[0].node.handle === activeCollection
        );
      });
    }

    const mappedProducts = filteredProducts.map((product) => {
      let showPrice = false;
      let productStatus = false;
      let productStatusLabel = "Sold";
      let madeToOrder = false;

      let unformattedPrice = product.node.variants.edges[0].node.priceV2.amount;

      let formattedPrice =
        unformattedPrice % 1
          ? priceDecimal.format(unformattedPrice)
          : priceRound.format(unformattedPrice);

      // Loop through all the variants and find if
      // there is a Made to Order variant with inventory
      product.node.variants.edges.map((variant) => {
        if (
          variant.node.title === "Made to Order" &&
          variant.node.availableForSale &&
          variant.node.quantityAvailable === product.node.totalInventory
        ) {
          madeToOrder = true;
        }
      });

      // Has the 'Disable Sales' environment toggle been activated?
      if (process.env.NEXT_PUBLIC_DISABLE_SALES === "true") {
        showPrice = true;
        productStatus = true;
        productStatusLabel = "Coming Soon";
      } else {
        // Does this product have any stock?
        if (product.node.availableForSale) {
          if (madeToOrder) {
            showPrice = true;
            productStatus = true;
            productStatusLabel = "Made to Order";
          } else {
            showPrice = true;
            productStatus = false;
            productStatusLabel = "In Stock";
          }
        } else {
          showPrice = false;
          productStatus = true;
          productStatusLabel = "Sold";
        }
      }

      return (
        <ProductRow
          key={"project-link-" + product.node.handle}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ type: "spring", duration: 1 }}
        >
          <NoScrollLink href={"/work/" + product.node.handle} passHref>
            <ProjectExcerpt
              whileHover={{ scale: 1.05 }}
              onHoverStart={(e) => {}}
              onHoverEnd={(e) => {}}
              transition={{ type: "spring", duration: 1 }}
            >
              <Image
                src={product.node.images.edges[0].node.originalSrc}
                width={product.node.images.edges[0].node.width}
                height={product.node.images.edges[0].node.height}
              />
              <LinkText>
                {product.node.title} {showPrice && `— ${formattedPrice} `}
                {productStatus && (
                  <ProductStatusLabel status={productStatusLabel}>
                    • {productStatusLabel}
                  </ProductStatusLabel>
                )}
              </LinkText>
            </ProjectExcerpt>
          </NoScrollLink>
        </ProductRow>
      );
    });

    return mappedProducts;
  }

  function handleFilter(collectionName) {
    setActiveCollection(null);

    setTimeout(() => {
      setActiveCollection(collectionName);
    }, 1050);
  }

  return (
    <ProductGrid spacingTop={props.spacingTop}>
      {/* {JSON.stringify(props.products, null, 2)} */}
      <ProductRow>
        <ProductFilter>
          <ProductFilterTab
            active={activeCollection === "all"}
            onClick={() => handleFilter("all")}
          >
            All Products
          </ProductFilterTab>
          <ProductFilterTab
            active={activeCollection === "homewares"}
            onClick={() => handleFilter("homewares")}
          >
            Homewares
          </ProductFilterTab>
          <ProductFilterTab
            active={activeCollection === "artwork"}
            onClick={() => handleFilter("artwork")}
          >
            Artwork
          </ProductFilterTab>
        </ProductFilter>
      </ProductRow>
      <AnimatePresence>{getCollectionItems()}</AnimatePresence>
    </ProductGrid>
  );
}

const ProductGrid = styled.div`
  position: relative;
  z-index: 500;
  background-color: white;
  padding: var(--gridMarginGap);
  padding-bottom: 25vh;
  margin-top: ${(props) => (props.spacingTop === "small" ? `15vh` : 0)};

  min-height: 100vh;
`;

const ProductFilter = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 4;
  padding-top: 10vh;

  @media screen and (min-width: 768px) {
    @media screen and (min-width: 768px) {
    grid-column: 2 / span 4;
  }
`;

const ProductFilterTab = styled.a`
  color: var(--colorContentPrimary);
  font-size: var(--step-5);
  font-weight: 300;
  line-height: 1;

  opacity: ${(props) => (props.active ? 1 : 0.4)};

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;

const ProductRow = styled(motion.div)`
  display: grid;
  column-gap: var(--gridColumnGap);
  grid-template-columns: var(--gridTemplateColumnsDefault);

  margin-bottom: 20vw;

  grid-template-areas: ". a a a a a";

  &:nth-child(odd) {
    grid-template-areas: "a a a a a .";
  }

  @media screen and (min-width: 768px) {
    margin-bottom: -5vw;

    grid-template-areas: ". . . . . . . a a a a .";

    &:nth-child(odd) {
      grid-template-areas: ". a a a a . . . . . . .";
    }

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
`;

const ProjectExcerpt = styled(motion.a)`
  grid-area: a;
  cursor: pointer;
`;

const LinkText = styled.span`
  display: inline-block;
  margin-top: var(--gridColumnGap);
  font-size: var(--step-1-5);
  font-weight: 400;
  color: var(--colorContentPrimary);
  grid-area: a;
`;

const ProductStatusLabel = styled.div`
  display: inline-block;
  color: ${(props) =>
    props.status === "Sold"
      ? `var(--colorBrandOrange)`
      : `var(--colorBrandPink)`};
`;
