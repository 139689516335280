const domain = process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN;
const storefrontAccessToken =
  process.env.NEXT_PUBLIC_SHOPIFY_STORE_FRONT_ACCESS_TOKEN;
const collection = process.env.NEXT_PUBLIC_SHOPIFY_COLLECTION;

// From here: https://github.com/btahir/next-shopify-starter/

async function callShopify(query) {
  const fetchUrl = `https://${domain}/api/2021-07/graphql.json`;

  const fetchOptions = {
    endpoint: fetchUrl,
    method: "POST",
    headers: {
      "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ query }),
  };

  try {
    const data = await fetch(fetchUrl, fetchOptions).then((response) =>
      response.json()
    );
    return data;
  } catch (error) {
    throw new Error("Could not fetch products!");
  }
}

export async function getAllProducts() {
  const query = `{
    products(first: 250, sortKey: CREATED_AT, reverse: true) {
      edges {
        node {
          id
          title
          description
          handle
          availableForSale
          totalInventory
          images(first: 250) {
            edges {
              node {
                id
                originalSrc
                height
                width     
                altText             
              }
            }
          }
          variants(first: 20) {
            edges {
              node {
                id
                title
                priceV2 {
                  amount
                  currencyCode
                }
                availableForSale
                quantityAvailable
              }
            }
          }
          collections(first: 10) {
            edges {
              node {
                title
                handle
              }
            }
          }
        }
      }
    }
  }`;
  const response = await callShopify(query);

  const allProducts = response.data.products.edges
    ? response.data.products.edges
    : [];

  return allProducts;
}

export async function getAllProductsInCollection() {
  const query = `{
        collectionByHandle(handle: "${collection}") {
          id
          title
          products(first: 250) {
            edges {
              node {
                id
                title
                description
                handle
                images(first: 250) {
                  edges {
                    node {
                      id
                      originalSrc
                      height
                      width     
                      altText             
                    }
                  }
                }
                variants(first: 250) {
                  edges {
                    node {
                      id
                      title
                      priceV2 {
                        amount
                        currencyCode
                      }            
                    }
                  }
                }
              }
            }
          }
        }
      }`;
  const response = await callShopify(query);

  const allProducts = response.data.collectionByHandle.products.edges
    ? response.data.collectionByHandle.products.edges
    : [];

  return allProducts;
}

export async function getProductSlugs() {
  const query = `{
      products(first: 250) {
        edges {
          node {
            handle              
          }
        }
      }
    }`;
  const response = await callShopify(query);

  const slugs = response.data.products.edges
    ? response.data.products.edges
    : [];

  return slugs;
}

export async function getProduct(handle) {
  const query = `{
      productByHandle(handle: "${handle}") {
        id
        title
        handle
        description
        descriptionHtml
        availableForSale
        totalInventory
        images(first: 250) {
          edges {
            node {
              id
              originalSrc
              transformedSrc (crop: CENTER, maxWidth: 300, maxHeight: 300) 
              height
              width     
              altText             
            }
          }
        }
        variants(first: 10) {
          edges {
            node {
              id
              title
              priceV2 {
                amount
                currencyCode
              }
              availableForSale
              quantityAvailable
            }
          }
        }
        collections(first: 5) {
          edges {
            node {
              title
              handle
            }
          }
        }
      }
    }`;
  const response = await callShopify(query);

  const product = response.data.productByHandle
    ? response.data.productByHandle
    : [];

  return product;
}

export async function createCheckout(lineItems) {
  const formattedLineItems = lineItems.map((item) => {
    return `{
      variantId: "${item.variantId}",
      quantity:${item.quantity}
    }`;
  });

  const query = `mutation 
      {
        checkoutCreate(input: {
          lineItems: [${formattedLineItems}]
        }) {
          checkout {
             id
             webUrl
             lineItems(first: 250) {
               edges {
                 node {
                   id
                   title
                   quantity
                 }
               }
             }
          }
        }
      }      
    `;
  const response = await callShopify(query);

  const checkout = response.data.checkoutCreate.checkout
    ? response.data.checkoutCreate.checkout
    : [];

  return checkout;
}

export async function updateCheckout(id, lineItems) {
  const formattedLineItems = lineItems.map((item) => {
    return `{
      variantId: "${item.variantId}",
      quantity:${item.quantity}
    }`;
  });

  const query = `mutation 
      {
        checkoutLineItemsReplace(lineItems: [${formattedLineItems}], checkoutId: "${id}") {
          checkout {
             id
             webUrl
             lineItems(first: 250) {
               edges {
                 node {
                   id
                   title
                   quantity
                 }
               }
             }
          }
        }
      }      
    `;
  const response = await callShopify(query);

  const checkout = response.data.checkoutLineItemsReplace.checkout
    ? response.data.checkoutLineItemsReplace.checkout
    : [];

  return checkout;
}

export async function checkoutInventorySync(queryString) {
  // queryString should be in the format: "(title:Bo) OR (title:Niko) OR (title:Late Spring 02.)"

  const query = `{
    products (first: 20, query: "${queryString}") {
      edges {
        node {
          id
          title
          totalInventory
          variants (first: 3) {
            edges {
              node {
                title
                quantityAvailable
              }
            }
          }
        }
      }
    }
  }     
    `;
  const response = await callShopify(query);

  const products = response.data.products.edges
    ? response.data.products.edges
    : [];

  return products;
}
